import React, { Component } from 'react';
import { graphql, withPrefix } from 'gatsby';
import Layout from '../components/Layout';
// import ProductComponent from '../components/HomeProductComponent';
// import PostComponent from '../components/PostComponent';

class Index extends Component {

    render() {

        const { siteMetadata } = this.props.data.site;
        // const postList = this.props.data.allMarkdownRemark;

        return (
            <Layout newsletter="true" about="true">
                <div className="row my-5">
                    <div className="col-12 my-5">
                        <div className="row my-5">
                            <div className="col-md-5 col-12 order-md-last my-auto c-a">
                                <h1 className="text-md-right text-center mb-4 t">{siteMetadata.main.title}</h1>
                                <p className="text-md-right text-center mt-4">{siteMetadata.main.description}</p>
                            </div>
                            <div className="col-md-5 col-12 order-first text-md-left text-center bg-patter py-4">
                                <img src={withPrefix(siteMetadata.main.image)} alt={siteMetadata.main.title} className="img w-50" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row mt-5 py-5 justify-content-center bg-title-box">
                    <div className="col-md-6 col-12 my-5">
                        <h1 className="text-center t">{siteMetadata.product.title}</h1>
                    </div>
                </div> */}

                {/* product-container */}
                {/* <div id="product-container" className="row justify-content-around pb-3">                
                    
                    <ProductComponent 
                        title='الکترون پروکسی'
                        link='/download/electronproxy-filtershekanha/'
                        imageSrc={withPrefix('/assets/electron_logo.png')}
                        excerpt="لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم . "
                    />

                </div>
                <div className="row justify-content-center my-5">
                    <Link to="/download" className="my-5 btn-paginate">بیشتر</Link>
                </div>
                <hr /> */}

                {/* blog-header */}
                {/* <div className="row dir-rtl justify-content-center my-5">
                    <div className="col-8 mt-5">
                        <h1 className="text-center mt-5"><span className="bg-title px-4 t">{siteMetadata.blog.title}</span></h1>
                    </div>
                    <div className="col-8 mt-4">
                        <p className="text-center">{siteMetadata.blog.description}</p>
                    </div>
                </div> */}

                {/* blog-post */}
                {/* <div className="row justify-content-around mb-1 pb-md-1 dir-rtl">
                    { postList.edges.map(({ node }, i) => (
                        <PostComponent post={node} key={i} />
                    ))}
                </div> */}

                {/* <div className="row justify-content-center my-5">
                    <Link to="/news" className="btn-paginate">بیشتر</Link>
                </div> */}

                <div className="row my-md-5 py-md-5"></div>
                
            </Layout>
        )
    }
}


export const query = graphql`
    query {
        site {
            siteMetadata {
                main {
                    title,
                    description,
                    image,
                    cover,
                    keyword
                }
                blog {
                    title,
                    description                 
                }
                product {
                    title
                }
            }
        }
        allMarkdownRemark(      
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: {posttype: {eq: "blog"} } } 
            limit: 6      
        ) {
        edges {
            node {
                fields{
                    slug
                }
                frontmatter {
                    date
                    title
                    description
                }
            }
        }}
    }
`;

export default Index;
